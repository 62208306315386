import {
    LaptopOutlined,
    DesktopOutlined,
    NotificationOutlined,
    UserOutlined,
    PieChartOutlined,
    TeamOutlined,
    FileOutlined,
    RiseOutlined,
    BarChartOutlined,
} from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, Slider, Progress, Button } from 'antd';
import React, { useState } from 'react';
import './SortVizPage.css';
import "antd/dist/antd.css";
import { HiCubeTransparent } from 'react-icons/hi';

import SortViz from './SortViz';

const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
    return {
        key,
        icon,
        children,
        label,
    };
}



// console.log(items2);
// console.table(items2);

/*
const items2 = [UserOutlined, LaptopOutlined, NotificationOutlined].map((icon, index) => {
  const key = String(index + 1);
  return {
    key: `sub${key}`,
    icon: React.createElement(icon),
    label: `subnav ${key}`,
    children: new Array(4).fill(null).map((_, j) => {
      const subKey = index * 4 + j + 1;
      return {
        key: subKey,
        label: `option${subKey}`,
      };
    }),
  };
});*/

const SortVizPage = () => {
    // Subnavbar
    const items22 = [
        /*getItem('Option 1', '1', <PieChartOutlined />),
        getItem('Option 2', '2', <DesktopOutlined />),*/
        getItem('Algorithm', 'sub1', <BarChartOutlined />, [
            getItem(<Button type="primary" onClick={() => selectAlgo(0)}>Bubble Sort</Button>, 'sub1/0'),
            getItem(<Button type="default" onClick={() => selectAlgo(1)}>Selection Sort</Button>, 'sub1/1'),
            getItem(<Button type="default" onClick={() => selectAlgo(2)}>Merge Sort</Button>, 'sub1/2'),
            getItem(<Button type="default" onClick={() => selectAlgo(3)}>Quick Sort</Button>, 'sub1/3'),
        ]),
        getItem('Array', 'sub2', <BarChartOutlined />, [
            getItem(<Button type="default">Random Array</Button>, '7'),
            getItem(<Button type="default">Custom Array</Button>, '8'),
        ]),
        getItem(
            <Slider
                min={1/10}
                defaultValue={1}
                max={10}
                step={0.1}
                onChange={(value) => setSpeed(value)}
                tipFormatter={(value) => `${value} ms`}
            />, 'slider1', 'Speed'),
        getItem(<Button type="primary">Sort</Button>, 'sort'),
        /*getItem('Team', 'sub2', <TeamOutlined />, [getItem('Team 1', '6'), getItem('Team 2', '8')]),
        getItem('Files', '9', <FileOutlined />),*/
    ];

    const [algo, setAlgo] = useState("Bubble Sort");
    const [items2, setItems2] = useState(items22);
    const [speed, setSpeed] = useState(1);

    const selectAlgo = (index) => {
        const temp = items2.slice();
        
        for (let i = 0; i < temp.length; i++) {
            const key = parseInt(temp[0].children[i].key.split("/")[1]);
            const algoName = temp[0].children[i].label.props.children;
            temp[0].children[i] = getItem(
                <Button
                    type={(i == index) ? "primary" : "default"}
                    onClick={() => selectAlgo(key)}>{algoName}
                </Button>,
                ("sub1/" + key),
            );
        }
        
        setItems2(temp);
        setAlgo(temp[0].children[index].label.props.children);
        //console.log(items2[0].children[1]);
    };

    return (
        <Layout>
            <Content
            style={{
                padding: '0 50px',
                height: '1000px',
            }}
            >
                {/*
            <Breadcrumb
                style={{
                margin: '16px 0',
                }}
            >
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>List</Breadcrumb.Item>
                <Breadcrumb.Item>App</Breadcrumb.Item>
            </Breadcrumb>*/}
            <Layout
                className="site-layout-background"
                style={{
                padding: '24px 0',
                }}
            >
                <Sider
                    className="site-layout-background"
                    width={200}
                    collapsed={false}
                    collapsedWidth={0}
                    style={{
                        background: `rgba(255, 255, 255, 0)`,
                    }}>
                <Menu
                    mode="inline"
                    defaultSelectedKeys={['1']}
                    defaultOpenKeys={['sub1']}
                    selectable={false}
                    style={{
                    height: '100%',
                    borderRadius: '25px',
                    }}
                    items={items2}
                />
                </Sider>
                <Content
                style={{
                    padding: '0 24px',
                    minHeight: 280,
                    overflow: 'auto'
                }}
                >
                    {/*<Progress percent={0} />*/}
                    <SortViz algo={algo} speed={speed} />
                    <br />
                    <h1>Bubble Sort</h1>
                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem IpsumLorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </Content>
            
            </Layout>
            </Content>
            <Footer
            style={{
                textAlign: 'center',
            }}
            >
            rudyorre
            </Footer>
        </Layout>
    );
};

export default SortVizPage;