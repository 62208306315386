import React from 'react';
import { Layout, Menu } from 'antd';
const { Header } = Layout;

const items = [
    {key:'Sort', label:<a href='/'>Sort</a>},
    {key:'About', label:<a href='/about'>About</a>}
];

const SortVizHeader = () => {
    return (
        <Header className="header">
            <a href='#'>
                <div className="logo" style={{ textAlign: "center" }}>
                    sortviz
                </div>
            </a>
            <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['2']} items={items} />
        </Header>
  );
}

export default SortVizHeader;
