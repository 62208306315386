import logo from './logo.svg';
import './App.css';
import SortViz from './SortViz/SortViz';
import SortVizPage from './SortViz/SortVizPage';
import { Navbar, NavItem } from './components/Navbar';
import SortVizHeader from './components/SortVizHeader';
import InvalidPage from './components/404page';
import AboutPage from './components/about';

import { LaptopOutlined, NotificationOutlined, UserOutlined } from '@ant-design/icons';

import { HashRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  console.log("process.env.PUBLIC_URL:");
  console.log(process.env.PUBLIC_URL);
  return (
    <>
      {/*<Router basename={process.env.PUBLIC_URL + '/'}>
        <SortVizHeader />
        <Routes>
        <Route path='/about' element={<AboutPage />} />
        <Route path='/' element={<SortVizPage />} />
        <Route path='*' element={<InvalidPage />}/>
        </Routes>
  </Router>*/}
      {/*<SortVizHeader />
      <SortVizPage />*/}
      <SortVizHeader />
      <Router basename={'/'}>
        <Routes>
          <Route exact path='/' element={<SortVizPage />} />
          <Route path='/about' element={<AboutPage />} />
          <Route path='*' element={<InvalidPage />}/>
        </Routes>
      </Router>
    </>
  );
}

export default App;

/*
<Router>
    <Navbar />
    <Routes>
        <Route exact path='/' exact element={<Home />} />
        <Route path='/about' element={<About/>} />
        <Route path='/contact' element={<Contact/>} />
        <Route path='/blogs' element={<Blogs/>} />
        <Route path='/sign-up' element={<SignUp/>} />
    </Routes>
    </Router>*/