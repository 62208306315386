import React from "react";

const AboutPage = () => {
    return (
        <div>
            <h1>About</h1>
            This website is just for visualizing some cool sorting algorithms.
        </div>
    );
}

export default AboutPage;