class Animation {
    // TYPES: 'compare', 'swap', 'assign'
    constructor(type, indices, value=null) {
        this.type = type;
        this.indices = indices;
        this.value = value;
    }
}

export const mergeSort = (array) => {
    const animations = [];
    mergeSortHelper(array, 0, array.length - 1, animations);
    return animations;
}


const mergeSortHelper = (array, low, high, animations) => {
    /**
     * Top
     */
    // Base case. A list of zero or one elements is sorted, by definition.
    if (high - low <= 1) {
        return array
    }

    // Recursive case. First, divide the list into equal-sized sublists
    // consisting of the first half and second half of the list.
    // This assumes lists start at index 0.

    const mid = Math.floor((low + high) / 2);

    // Recursively sort both sublists.
    mergeSortHelper(array, low, mid, animations);
    mergeSortHelper(array, mid + 1, high, animations);

    // Then merge the now-sorted sublists.
    merge(array, low, high, animations);
}

const merge = (array, low, high, animations) => {
    /**
     * Merges into sorted array.
     */
    const mid = Math.floor((low + high) / 2);
    let [i1, i2] = [low, mid + 1];
    const merged = [];
    let current = 0;
    let deplete1 = i1 > mid;
    let deplete2 = i2 > high;
    while (!deplete1 && !deplete2) {
        deplete1 = i1 > mid;
        deplete2 = i2 > high;
        if (!deplete1 && (deplete2 || (array[i1] < array[i2]))) {
            merged.push(array[i1]);
            i1++;
        } else {
            merged.push(array[i2]);
            i2++;
        }
        current++;
    }

    const new_array = [];
    for (let i = low; i <= mid; i++) {
        let j = i + (mid - low) + 1;
        if (j != high + 1) {
            animations.push(new Animation('compare', [i, j]));
        } else {
            animations.push(new Animation('compare', [i, i]));
        }
    }
    for (let i = low; i <= high; i++) {
        new_array.push(array[i]);
    }
    new_array.sort((a,b) => a - b);

    for (let i = high; i >= low; i--) {
        let value = new_array.pop();
        array[i] = value;
        // TODO make it so you dont' have to have two of the same values for 'assign' type
        animations.push(new Animation('assign', [i, i], value));
    }
}

export const quickSort = (array, animate=true) => {
    let arr = [...array];
    const animations = [];
    quickSortHelper(arr, 0, arr.length - 1, animations);
    return animate ? animations : arr;
}

const quickSortHelper = (array, low, high, animations) => {
    /**
     * Sorts portion of an array, divides it into partitions, then sorts those.
     */

    // Ensure order of indices.
    if (low >= high || low < 0) {
        return;
    }

    // Partition array and get pivot index.
    const pivot = partition(array, low, high, animations);

    // Sort the two partitions.
    quickSortHelper(array, low, pivot - 1, animations);
    quickSortHelper(array, pivot + 1, high, animations);
}

const partition = (array, low, high, animations) => {
    /**
     * Divides an array into two partitions.
     */

    // Choose last element as pivot.
    const pivot = high;
    // const pivots = [low, Math.floor((low + high) / 2), high];
    // pivots.sort((a,b) => array[a] - array[b]);
    // const pivot = pivots[1];


    // Temp pivot index.
    let i = low - 1;

    for (let j = low; j < high; j++) {
        // If current element is less than or equal to the pivot value.
        animations.push(new Animation('compare', [i, pivot]));
        if (array[j] <= array[pivot]) {
            // Move temporary pivot index forward.
            i += 1;
            // Swap the current element with the element at the temporary pivot index.
            // TODO: single line swap (like python)
            animations.push(new Animation('swap', [i, j], [array[i], array[j]]));
            const temp = array[i];
            array[i] = array[j];
            array[j] = temp;
        }
    }

    // Move the pivot element to the correct pivot position (between the smaller and larger elements).
    i += 1;
    animations.push(new Animation('swap', [i, high], [array[i], array[high]]));
    const temp = array[i];
    array[i] = array[high];
    array[high] = temp;

    // Return the pivot index.
    return i;
}

export const bubbleSort = (array, animate=true) => {
    /**
     * Performs bubble sort on `array`, and returns the necessary animations for sorting or the sorted array itself.
     */
    let arr = [...array];
    let animations = [];
    for (let i = 0; i < arr.length; i++) {
        for (let j = i + 1; j < arr.length; j++) {
            if (arr[i] > arr[j]) {
                animations.push(new Animation('swap', [i,j], [arr[i], arr[j]]));
                let temp = arr[i];
                arr[i] = arr[j];
                arr[j] = temp;
            } else {
                animations.push(new Animation('compare', [i,j]));
            }
        }
    }
    return (animate) ? animations : arr;
}

export const selectionSort = (array, animate=true) => {
    /**
     * Performs selection sort on `array`, and returns the necessary animations for sorting or the sorted array itself.
     */
    let arr = [...array];
    let animations = [];
    for (let i = 0; i < arr.length; i++) {
        let min = i;
        for (let j = i + 1; j < arr.length; j++) {
            animations.push(new Animation('compare', [min, j]));
            if (arr[j] < arr[min]) {
                min = j;
            }
        }
        animations.push(new Animation('swap', [i, min], [arr[i], arr[min]]));
        let temp = arr[i];
        arr[i] = arr[min];
        arr[min] = temp;
    }
    return (animate) ? animations : arr;
}