import React, { useState, useEffect, useRef, createRef } from 'react';
import './SortViz.css';
import { bubbleSort, mergeSort, quickSort, selectionSort } from '../algorithms/algorithms';
import { Progress } from 'antd';

// TODO: Replace hard coded values into variable ones that depend on screen size.
// TODO: Custom color based off of theme.

const ANIMATION_SPEED_MS = 1;
const NUMBER_OF_ARRAY_BARS = 100 // 250;
const PRIMARY_COLOR = 'cyan';
const SECONDARY_COLOR = 'magenta';
const COMPARE_COLOR = 'yellow';
const SWAP_COLOR = 'magenta';

const SortViz = (props) => {

    const [array, setArray] = useState([]);
    const [mountStatus, setMountStatus] = useState(false);
    const [progress, setProgress] = useState(0);
    const timerRefs = useRef([].map(() => createRef()));
    const [algo, setAlgo] = useState('');
    const arrayBoxRef = useRef(null);
    const [length, setLength] = useState(NUMBER_OF_ARRAY_BARS);
    const [arrayBarWidth, setArrayBarWidth] = useState(1);
    const [arrayBarMargin, setArrayBarMargin] = useState(1);

    useEffect(() => {
        // Initialization
        if (!mountStatus) {
            resetArray();
            setMountStatus(true);
        }
        
        // On Render
        setAlgo(props.algo);
        setArrayBarWidth(getWidth().width);
        setArrayBarMargin(getWidth().margin);

        // Event Listeners
        window.addEventListener('resize', () => {
            setArrayBarWidth(getWidth().width);
            setArrayBarMargin(getWidth().margin);
        });

    });

    const getWidth = () => {
        /**
         * Calculates the width of the array-bars depending on the overall width of the content box,
         * the padding of the array-box, and the number of bars (`length`).
         */
        const ref = arrayBoxRef.current;
        if (ref == null) {
            return 1;
        }
        let remainingWidth = ref.offsetWidth;
        remainingWidth -= 2 * parseInt(ref.style.padding);
        // remainingWidth -= 2 * length; // margins of array-bar (1px on each side)
        remainingWidth /= length;
        return {width: remainingWidth * 0.9, margin: remainingWidth * 0.1 / 2};
    }

    const resetArray = (size=length, interval=[0,1], multiplier=500, clean=true) => {
        /**
         * Generates an array of random values used for sorting visualization.
         * @param {int} size The length of the array.
         * @param {array} interval The interval to pick random integers from.
         * @param {boolean} clean Flag for making all of the bars generated uniformly.
         * @return {undefined} Modifies the state variable `array`.
         */
        const arr = [];
        if (clean) {
            const bucket = [];
            for (let i = 0; i < size; i++) {
                bucket.push((Math.floor(interval[1] - interval[0]) / size) * i + interval[0]);
            }
            while (bucket.length > 0) {
                var randomIndex = Math.floor(Math.random() * bucket.length);
                arr.push(bucket.splice(randomIndex, 1)[0] * multiplier);
            }
        } else {
            for (let i = 0; i < size; i++) {
                arr.push(randomIntFromInterval(interval[0], interval[1]));
            }
        }
        setArray(arr);
        setProgress(0);

        // Update css accordingly (just in case)
        if (!mountStatus) {
            const arrayBars = document.getElementsByClassName('array-bar');
            for (let i = 0; i < size; i++) {
                //arrayBars[i].style.height = `${arr[i]}px`;
            //   ara
            }
        }
    };

    const clearTimers = () => {
        for (let i = 0; i < timerRefs.current.length; i++) {
            clearTimeout(timerRefs.current[i].current);
        }
        resetArray();
    }

    const merge = () => {
        // const testArray = this.state.array.slice().sort((a,b) => a - b);
        // const sortedArray = mergeSort(this.state.array);
        // console.log(arraysEqual(testArray, sortedArray));
        const animations = mergeSort(array.slice());
        animate(animations, 5);
    };

    const quick = () => {
        // resetArray();
        const testArray = array.slice().sort((a,b) => a - b);
        const sortArray = quickSort(array, false);
        console.log(arraysEqual(testArray, sortArray));
        console.log(sortArray);
        
        const animations = quickSort(array);
        animate(animations, 1);
    };

    const heap = () => {};

    const bubble = () => {
        const testArray = array.slice().sort((a,b) => a - b);
        const sortArray = bubbleSort(array.slice(), false);
        console.log(arraysEqual(testArray, sortArray));
        const animations = bubbleSort(array.slice());
        animate(animations, 1/5);
    };

    const selection = () => {
        const animations = selectionSort(array.slice());
        animate(animations, 1/5);
    }

    const animate = (animations, speedModifier=1) => {
        clearTimers();
        let [prevI, prevJ] = [0, 0];
        
        // Clear timer refs
        timerRefs.current = [];

        for (let i = 0; i < animations.length; i++) {
            let timer = setTimeout(() => {
                const arrayBars = document.getElementsByClassName('array-bar');
                const type = animations[i].type;
                const [I,J] = animations[i].indices;
                const arr = array.slice();
                const temp = arr[I];
                // array[I] = array[J];
                // array[J] = temp;
                
                // this.setState({array})
                
                arrayBars[prevI].style.backgroundColor = PRIMARY_COLOR;
                arrayBars[prevJ].style.backgroundColor = PRIMARY_COLOR;
                if (type == 'compare') {
                    arrayBars[I].style.backgroundColor = COMPARE_COLOR;
                    arrayBars[J].style.backgroundColor = COMPARE_COLOR;
                } else if (type == 'swap') {
                    const temp1 = arr[I];
                    arr[I] = arr[J];
                    arr[J] = temp1;
                    setArray(arr => arr);
                    const temp2 = arrayBars[I].style.height;
                    arrayBars[I].style.height = `${animations[i].value[1]}px`; // arrayBars[J].style.height;
                    arrayBars[J].style.height = `${animations[i].value[0]}px`; //temp2;
                    arrayBars[I].style.backgroundColor = SWAP_COLOR;
                    arrayBars[J].style.backgroundColor = SWAP_COLOR;
                } else if (type == 'assign') {
                    // const temp = arr[I];
                    // arr[I] = arr[J];
                    // arr[J] = temp;
                    // const temp1 = arr[I];
                    arr[I] = animations[i].value;
                    setArray(arr => arr);
                    arrayBars[I].style.height = `${animations[i].value}px`;
                    arrayBars[I].style.backgroundColor = SWAP_COLOR;
                }
                [prevI, prevJ] = [I, J];

                // Animate progress bar
                const newProgress = Math.ceil(i / animations.length * 10000) / 100;
                if (i % Math.ceil(animations.length / 10000) == 0) {
                    setProgress(newProgress);
                }
            }, i * ANIMATION_SPEED_MS * speedModifier);

            // Store reference to timers
            timerRefs.current.push(() => createRef());
            timerRefs.current[timerRefs.current.length - 1].current = timer;
        }

        // Last animation
        let timer = setTimeout(() => {
            const arrayBars = document.getElementsByClassName('array-bar');
            arrayBars[prevI].style.backgroundColor = PRIMARY_COLOR;
            arrayBars[prevJ].style.backgroundColor = PRIMARY_COLOR;
            setProgress(100);
        }, animations.length * ANIMATION_SPEED_MS * speedModifier);
        
        // Store reference to last timer
        timerRefs.current.push(() => createRef());
        timerRefs.current[timerRefs.current.length - 1].current = timer;
    }
    
    const sort = () => {
        const algos = {
            'Bubble Sort':bubble,
            'Selection Sort':selection,
            'Merge Sort':merge,
            'Quick Sort':quick,
        };
        let animations = [];
        let speed = 1;
        if (algo == 'Bubble Sort') {
            animations = bubbleSort(array.slice());
            speed = 1/5;
        } else if (algo == 'Selection Sort') {
            animations = selectionSort(array.slice());
            speed = 1/5;
        } else if (algo == 'Merge Sort') {
            animations = mergeSort(array.slice());
            speed = 5;
        } else if (algo == 'Quick Sort') {
            animations = quickSort(array.slice());
            speed = 5;
        }
        animate(animations, props.speed);
    }

    return (
        <div>
            <div className="array-container">
                <div
                    id="array-box"
                    ref={arrayBoxRef}
                    style={{
                        backgroundColor: 'white',
                        borderStyle: '',
                        borderColor: 'black',
                        borderRadius: '25px',
                        padding: '20px',
                    }}
                >
                    {array.map((value, idx) => (
                        <div
                            className="array-bar"
                            key={idx}
                            style={{
                                width: `${arrayBarWidth}px`,
                                marginLeft: `${arrayBarMargin}px`,
                                marginRight: `${arrayBarMargin}px`,
                                backgroundColor: PRIMARY_COLOR,
                                height: `${value}px`,
                            }}
                        ></div>
                    ))}
                </div>
                <br />
                {algo}
                <br />
                <Progress type={'circle'} percent={progress} format={(progress) => `${Math.ceil(progress)}%`}/>
                <br />
                <br />
                <button onClick={() => {clearTimers();resetArray()}}>Generate New Array</button>
                <button onClick={() => {getWidth()}}>Width</button>
                {/*<button onClick={() => merge()}>Merge Sort</button>
                <button onClick={() => quick()}>Quick Sort</button>
                {/*<button onClick={() => heap()}>Heap Sort</button>}
                <button onClick={() => bubble()}>Bubble Sort</button>
                <button onClick={() => selection()}>Selection Sort</button>*/}
                <button onClick={() => sort()}>Sort</button>
            </div>
        </div>
    );
}

export default SortViz;

function randomIntFromInterval(min, max) {
    /**
     * https://stackoverflow.com/questions/4959975/generate-random-number-between-two-numbers-in-javascript
     * @param {int} min
     * @param {int} max 
     * @return {int} Random integer between min and max.
     */
    return Math.floor(Math.random() * (max - min + 1) + min);
}

function arraysEqual(a, b) {
    /**
     * https://stackoverflow.com/questions/3115982/how-to-check-if-two-arrays-are-equal-with-javascript
     */
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
    for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}